import Image from 'next/image'
import React from 'react'

import Modal from '@components/modal'
import H2 from '@components/typography/heading-two'
import Paragraph from '@components/typography/paragraph'

import { useAppSelector } from '@hooks/redux'

import { ATMA_CS_PHONE_NUMBER } from '@constants/phone-number'
import shareToWhatsApp from '@constants/whatsapp-url'

import RedeemVoucherTransactionDetail from '../redeem-voucher-transaction-detail'
import RedeemVoucherTransactionDetailInfo from '../redeem-voucher-transaction-detail-info/RedeemVoucherTransactionDetailInfo'

type Props = {
  open: boolean
  onClose: () => void
}

const RedeemVoucherModal = ({ open, onClose }: Props) => {
  const { redeemCodeDetail } = useAppSelector((state) => state.coins)

  const redeemCodeText = () => {
    if (redeemCodeDetail?.status === 'Success') {
      return 'Berhasil!'
    }

    if (redeemCodeDetail?.status === 'Pending') {
      return 'Diproses'
    }

    if (redeemCodeDetail?.status === 'Failed') {
      return 'Gagal'
    }
  }

  return (
    <Modal isHeaderHidden open={open} onClose={onClose} className="w-[30rem]">
      <div className="flex items-center justify-between pt-4">
        <div className="w-full text-center">
          <H2>{redeemCodeText()}</H2>
        </div>
        <button type="button" onClick={onClose} className="h-5 w-5">
          <Image
            alt="icon"
            src="/assets/icons/ic_close.svg"
            width={24}
            height={24}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </button>
      </div>
      <div className="mt-4 rounded-xl border-2">
        <RedeemVoucherTransactionDetail />
        <RedeemVoucherTransactionDetailInfo />
      </div>

      {redeemCodeDetail?.status !== 'Pending' && (
        <div className="mt-4 flex items-start gap-x-2 rounded-lg bg-[#F4F6FB] p-2">
          <Image
            alt="icon"
            src="/assets/icons/ic_price_list.svg"
            width={30}
            height={30}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
          <Paragraph className="text">
            {redeemCodeDetail?.status === 'Success'
              ? 'Jika belum menerima Atma Coins, hubungi Customer Service.'
              : 'Transaksi gagal, saldo tidak bertambah.'}
          </Paragraph>
        </div>
      )}

      <a
        type="button"
        href={shareToWhatsApp('+62', ATMA_CS_PHONE_NUMBER)}
        target="_blank"
        rel="noreferrer"
        className="mt-4 flex w-full items-center justify-center space-x-2 rounded-xl border-2 border-primary-blue p-4 font-semibold text-primary-blue hover:bg-primary-blue hover:text-white"
      >
        <Image
          alt="icon"
          src="/assets/icons/ic_whatsapp_green.svg"
          width={20}
          height={20}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
        <p>Ada pertanyaan? Tanya CS</p>
      </a>
    </Modal>
  )
}

export default RedeemVoucherModal

/* eslint-disable arrow-body-style */
import { format } from 'date-fns'
import locale from 'date-fns/locale/id'
import Image from 'next/image'
import React from 'react'
import NumberFormat from 'react-number-format'

import Paragraph from '@components/typography/paragraph'

import { useAppSelector } from '@hooks/redux'

import TransactionStatusBadge from '../transaction-status-badge'

const RedeemVoucherTransactionDetail = () => {
  const { redeemCodeDetail } = useAppSelector((state) => state.coins)

  if (!redeemCodeDetail) return null

  return (
    <div className="border-b-2 p-4">
      <div className="flex flex-col items-center justify-center">
        <Paragraph weight="semibold" className="mt-2">
          Penukaran voucher
        </Paragraph>
      </div>

      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-x-1">
          <p className="text-[12px] font-[400] text-primary-gray">
            {format(
              new Date(redeemCodeDetail.createdAt as string),
              'dd MMMM yyyy - HH:mm',
              {
                locale,
              }
            )}
          </p>
        </div>
        <TransactionStatusBadge status={redeemCodeDetail.status} />
      </div>

      <div className="mt-4 flex items-center justify-between">
        <Paragraph accent="secondary" className="text-sm font-thin">
          Atma Coins didapat
        </Paragraph>
        <div className="flex gap-x-2">
          <Image
            alt="icon"
            src="/assets/icons/ic_coin.svg"
            width={20}
            height={20}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
          <Paragraph weight="semibold">
            <NumberFormat
              fixedDecimalScale={false}
              decimalSeparator=","
              thousandSeparator="."
              decimalScale={0}
              value={redeemCodeDetail.amount}
              displayType="text"
            />
          </Paragraph>
        </div>
      </div>

      {redeemCodeDetail.status !== 'Failed' && (
        <div className="mt-4 flex items-center justify-between">
          <Paragraph accent="secondary" className="text-sm font-thin">
            Akan kedaluwarsa pada
          </Paragraph>
          <Paragraph className="text-sm font-thin">
            {format(new Date(redeemCodeDetail.expiryAt), 'dd MMMM yyyy', {
              locale,
            })}
          </Paragraph>
        </div>
      )}
    </div>
  )
}

export default RedeemVoucherTransactionDetail

/* eslint-disable arrow-body-style */
import Image from 'next/image'
import React from 'react'
import { toast } from 'react-toastify'

import Paragraph from '@components/typography/paragraph'

import { useAppSelector } from '@hooks/redux'

const RedeemVoucherTransactionDetailInfo = () => {
  const { redeemCodeDetail } = useAppSelector((state) => state.coins)

  if (!redeemCodeDetail) return null

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <Paragraph accent="secondary" className="text-sm font-thin">
          ID transaksi
        </Paragraph>

        <div className="flex gap-x-2">
          <Paragraph className="text-end text-sm font-thin">
            {redeemCodeDetail.referenceId}
          </Paragraph>
          <button
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(
                redeemCodeDetail.referenceId as string
              )
              toast('ID transaksi berhasil disalin.', {
                hideProgressBar: true,
                style: {
                  backgroundColor: '#52B788',
                  color: '#FFFFFF',
                },
              })
            }}
          >
            <Image
              alt="icon"
              src="/assets/icons/ic_copy.svg"
              width={20}
              height={20}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default RedeemVoucherTransactionDetailInfo
